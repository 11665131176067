<template>
    <BackofficeBase :loader_prop="loader_prop">
     
      <div >
        <div>
          {{data_general.company_name}} <br>
          {{data_general.phone}}
        </div>
        <div class="item_action">
         
            <br>
            <div v-for="data_details in data_details" :key="data_details.id" class="item_list">
                שם הלקוח: {{ data_details.enduser_name }} <br>
                מספר חבילה: {{ data_details.package_number }} <br>
                סטטוס: {{ data_details.status }} <br>
                נקודת חלוקה: {{ data_details.distribution_point_name }}<br> 
                (שרת)זמן משלוח: {{ data_details.formatted_ts }}<br>
            </div>
        </div>
      </div>
    </BackofficeBase>
  </template>
  
  <script>
  import BackofficeBase from "./BackofficeBase";
  import BackendModel from "../../models/BackendModel";
  
  export default {
    name: 'BackofficeUsers',
    components: {
      BackofficeBase
    },
    data() {
      return {
        loader_prop : true,
        data : [],
        casual_couriers_id:'0'
      }
    },
    methods: {
    
    async getCouriersDetails() {
      let backendModel = new BackendModel();
      let data = await backendModel.backendRequest("/Api/service/backoffice/get_casual_couriers_details",  {id : this.$route.query.id});
      this.data_general = data.data.data.general[0];
      this.data_details = data.data.data.details;
      console.log(this.data)
      this.loader_prop = false;
    }
  },
  async mounted() {
    await this.getCouriersDetails();  
    console.log(this.data);
  }
}
  </script>
  
  <style scoped>
  @import "../../assets/style.css"
  </style>
  